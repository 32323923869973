<template>
  <div>
    <div class="header">
      <div v-show="!isEdit" class="header__version-select">
        <el-select
          ref="refId"
          v-model="currentConfigurationId"
          size="small"
          filterable
          popper-class="select_own"
          @change="selectConfig"
        >
          <el-option
            :key="'add_new'"
            class="version-add-option"
            :value="currentConfigurationId"
          >
            <div
              v-limits-of-authority="'CONFIG_ITEM_CREATE_EDIT_COPY'"
              class="version-add"
              style="display: flex; justify-content: center"
              @click="addConfigurationDialogShow"
            >
              <i class="iconfont icon-add"></i>
            </div>
          </el-option>
          <el-option
            v-for="item in configurationOptions"
            :key="item.configItemId"
            :label="item.name"
            :value="item.configItemId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header__version-select">
        <el-input
          v-show="isEdit"
          v-model="currentConfigObj.name"
          size="small"
          @blur="editConfig"
        >
        </el-input>
      </div>
      <div style="display: flex; align-items: center">
        <div v-show="isAutoSave && isEdit">
          <i class="iconfont icon-refresh" style="margin-right: 5px"> </i
          ><span
            style="
              margin-right: 10px;
              font-size: 12px;
              color: rgba(166, 166, 166, 1);
            "
            >{{ $t("baseline.topBar.autoSave") }}</span
          >
        </div>
        <!-- <div v-if="!isEdit">
          <i class="iconfont icon-save-list hover-icon"></i>
        </div> -->
        <!-- <el-button
          v-if="!isEdit"
          style="margin-left: 10px"
          type="info"
          size="small"
        >
          <i class="iconfont icon-save1" style="margin-right: 5px"></i>
          {{ $t("baseline.archive.title1") }}
        </el-button> -->
        <span>
          <el-button
            v-limits-of-authority="'CONFIG_ITEM_DELETE'"
            type="info"
            size="small"
            style="margin-right: 5px"
            :disabled="limits_of_authority_disable('CONFIG_ITEM_DELETE')"
            @click="deleteFn"
            ><i class="iconfont icon-delete"></i
            >{{ $t("btn.deleteBtn") }}</el-button
          >
        </span>
        <div style="margin-right: 10px" @click="getArchiveData">
          <i class="iconfont icon-save-list hover-icon"></i>
        </div>
        <el-button
          type="info"
          size="small"
          @click="archive_config_dialog = true"
        >
          <i class="iconfont icon-save1"></i>
          {{ $t("baseline.archive.title1") }}
        </el-button>
        <el-button
          v-if="!isEdit"
          v-limits-of-authority="'CONFIG_ITEM_CREATE_EDIT_COPY'"
          type="info"
          :disabled="
            limits_of_authority_disable('CONFIG_ITEM_CREATE_EDIT_COPY')
          "
          @click="copyDialogFn"
          ><i class="iconfont icon-copy" style="margin-right: 5px"></i
          >{{ $t("btn.copyBtn") }}</el-button
        >
        <el-button
          v-if="!isEdit"
          v-limits-of-authority="'CONFIG_ITEM_CREATE_EDIT_COPY'"
          type="primary"
          :disabled="
            limits_of_authority_disable('CONFIG_ITEM_CREATE_EDIT_COPY')
          "
          @click="editFn"
          ><i class="iconfont icon-branch_edit" style="margin-right: 5px"></i
          >{{ $t("btn.editBtn") }}</el-button
        >

        <el-button v-if="isEdit" type="info" size="small" @click="exitEdit"
          ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
          {{ $t("btn.exitEditBtn") }}</el-button
        >
      </div>
    </div>
    <div @mouseenter="summaryOnmouserEnter" @mouseleave="summaryOnmouserLeave">
      <el-collapse v-model="activeNames">
        <el-collapse-item class="collapse_item" name="1">
          <div slot="title" class="collapse-title">
            {{ $t(`nodeDetail.basicInfo.title`) }}
          </div>
          <div class="version-info">
            <el-row>
              <el-col :span="8">
                <span style="font-weight: 700">
                  {{ $t("chart.basicInfo.creator") }}:
                </span>
                <span>{{
                  matchUserNickname(currentConfigObj.createdBy, "")
                }}</span>
              </el-col>
              <el-col :span="8">
                <span style="font-weight: 700">
                  {{ $t("chart.basicInfo.createTime") }}:
                </span>
                <span>
                  {{
                    new Date(currentConfigObj.createdAt).Format(
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="24">
                <span style="font-weight: 700">
                  {{ $t("chart.basicInfo.desc") }}:
                </span>
                <span v-if="!isEdit">{{ currentConfigObj.description }}</span>
                <span
                  ><el-input
                    v-show="isEdit"
                    v-model="currentConfigObj.description"
                    size="small"
                    type="textarea"
                    :autosize="{ maxRows: 4 }"
                    @blur="editConfig"
                  >
                  </el-input
                ></span>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div>
      <div class="scroll_body">
        <!-- 添加节点 -->
        <div class="add_node_wrap">
          <div id="quick_select">
            <el-select
              v-model="fileSelect1"
              multiple
              size="small"
              :placeholder="$t('configurationManagement.addPlaceHolder1')"
              filterable
              clearable
              remote
              style="margin-right: 10px; width: 288px; overflow: hidden"
              :remote-method="getFileOptions"
              :popper-append-to-body="true"
              collapse-tags
              :loading="searchLoading"
              popper-class="select-icon-height-auto-class"
              reserve-keyword
            >
              <el-option
                v-for="item in fileOptions"
                :key="item.id"
                :value="item.id"
                :label="item.key + item.name"
                style="text-align: left"
              >
                <!-- ms8613代码定位 -->
                <select-icon
                  :file="true"
                  :item="{
                    fileKey: item.key,
                    fileName: item.name,
                    fileTypeId: item.fileTypeId,
                  }"
                >
                </select-icon>
              </el-option>
            </el-select>
          </div>
          <span>
            <el-button
              v-limits-of-authority="'CONFIG_ITEM_EDIT_DELETE'"
              type="primary"
              :disabled="limits_of_authority_disable('CONFIG_ITEM_EDIT_DELETE')"
              style="vertical-align: text-bottom"
              @click="addFileConfigItem"
            >
              {{ $t("btn.addBtn") }}</el-button
            >
          </span>
        </div>
        <collapse class="table_collapse">
          <template slot="title">
            {{ $t("configurationManagement.block1") }}
          </template>

          <template slot="pagination">
            <el-pagination
              :page-size="page1Obj.pageSize"
              :current-page.sync="page1Obj.currentPage"
              class="header-pagination"
              :background="false"
              small
              layout="prev, pager, next, total"
              :total="page1Obj.total"
              @current-change="table1pageChange"
            >
            </el-pagination>
          </template>
          <div class="baseline-nodes">
            <el-table
              :data="tableData1"
              :header-cell-style="{
                color: 'rgba(84, 85, 86, 1)',
                fontWeight: 'blod',
                fontSize: '14px',
                backgroundColor: 'transparent',
              }"
              height="31vh"
            >
              <el-table-column width="50px">
                <template slot="header" slot-scope="scope">
                  <el-dropdown
                    trigger="click"
                    style="cursor: pointer"
                    class="notDrag"
                    placement="bottom-start"
                  >
                    <span
                      class="word_button"
                      style="display: flex; align-items: center"
                    >
                      <i
                        class="notDrag iconfont icon-a-31shezhi"
                        style="font-size: 20px; margin-right: 5px"
                      />
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      :append-to-body="true"
                      class="notDrag"
                    >
                      <div
                        v-show="
                          !limits_of_authority_disable(
                            'CONFIG_ITEM_EDIT_DELETE'
                          )
                        "
                        class="version-add"
                        style="
                          display: flex;
                          justify-content: center;
                          cursor: pointer;
                          color: #30648f;
                          margin: 4px 0;
                        "
                        @click="addCustomFieldsDialogShow"
                      >
                        <i class="iconfont icon-add"></i>
                      </div>
                      <el-checkbox-group
                        v-model="showColumn"
                        class="select"
                        style="max-height: 300px"
                        @change="changeShowFields"
                      >
                        <div
                          v-for="type in allFields"
                          :key="type.prop"
                          style="position: relative"
                        >
                          <el-checkbox :label="type.label" class="checkbox">
                            <el-tooltip
                              v-ak-tooltip-auto-show
                              class="item"
                              effect="dark"
                              :content="type.label"
                            >
                              <span
                                style="
                                  display: inline-block;
                                  width: 100px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  vertical-align: bottom;
                                  margin-right: 10px;
                                "
                              >
                                {{ type.label }}
                              </span>
                            </el-tooltip>
                          </el-checkbox>
                          <i
                            v-if="type.type == 'cus'"
                            v-show="
                              !limits_of_authority_disable(
                                'CONFIG_ITEM_EDIT_DELETE'
                              )
                            "
                            class="iconfont icon-edit"
                            style="
                              color: #004caa;
                              position: absolute;
                              cursor: pointer;
                              right: 20px;
                              top: 2px;
                            "
                            @click.stop="editFields(type)"
                          />
                          <i
                            v-if="type.type == 'cus'"
                            v-show="
                              !limits_of_authority_disable(
                                'CONFIG_ITEM_EDIT_DELETE'
                              )
                            "
                            class="el-icon-delete"
                            style="
                              cursor: pointer;
                              color: #ff4823;
                              font-size: 18px;
                              position: absolute;
                              right: 0;
                              top: 0;
                            "
                            @click.stop="deleteFields(type)"
                          ></i>
                        </div>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in table1HeaderList"
                :key="index"
                show-overflow-tooltip
                :label="item.label"
                width="200px"
              >
                <template slot="header" slot-scope="scope">
                  <div
                    v-if="item.type === 'sys' && item.prop === 'processAreaId'"
                  >
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        :class="{
                          'checked-label':
                            table1CheckprocessAreaList.length > 0,
                        }"
                      >
                        <span>{{ item.label }}</span>
                        <i
                          class="el-icon-arrow-down el-icon--right"
                          style="color: #004caa"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div
                          v-show="
                            !limits_of_authority_disable(
                              'CONFIG_ITEM_EDIT_DELETE'
                            )
                          "
                          class="version-add"
                          style="
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                            color: #30648f;
                            margin: 4px 0;
                          "
                          @click="addprocessAreaDialogShow"
                        >
                          <i class="iconfont icon-add"></i>
                        </div>
                        <el-dropdown-item>
                          <el-checkbox
                            v-model="table1CheckprocessAreaAll"
                            :indeterminate="
                              table1CheckprocessAreaIsIndeterminate
                            "
                            @change="table1CheckprocessAreaHandleCheckAllChange"
                            @click.native.stop
                            >{{ $t("test.export.all") }}</el-checkbox
                          ></el-dropdown-item
                        >
                        <el-checkbox-group
                          v-model="table1CheckprocessAreaList"
                          style="max-height: 300px; overflow-y: scroll"
                          @change="table1CheckprocessAreaHandleCheckChange"
                        >
                          <el-dropdown-item
                            v-for="(process, processindex) in processOptions"
                            :key="processindex"
                            ><div style="position: relative">
                              <el-checkbox
                                :label="process.processAreaId"
                                @click.native.stop
                              >
                                <el-tooltip
                                  v-ak-tooltip-auto-show
                                  class="item"
                                  effect="dark"
                                  :content="process.name"
                                >
                                  <span
                                    style="
                                      display: inline-block;
                                      width: 100px;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      vertical-align: bottom;
                                      margin-right: 10px;
                                    "
                                  >
                                    {{ process.name }}
                                  </span>
                                </el-tooltip></el-checkbox
                              >

                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && process.name !== 'null'
                                "
                                class="iconfont icon-edit"
                                style="
                                  color: #004caa;
                                  cursor: pointer;
                                  right: 20px;
                                  top: 2px;
                                "
                                @click.stop="editProcessAreaDialog(process)"
                              />
                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && process.name !== 'null'
                                "
                                class="el-icon-delete"
                                style="
                                  cursor: pointer;
                                  color: #ff4823;
                                  font-size: 18px;
                                  right: 0;
                                  top: 0;
                                "
                                @click.stop="deleteProcessArea(process)"
                              ></i>
                            </div>
                          </el-dropdown-item>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div
                    v-else-if="
                      item.type === 'sys' && item.prop === 'controlMethodId'
                    "
                  >
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        :class="{
                          'checked-label': table1CheckcontolList.length > 0,
                        }"
                      >
                        <span>{{ item.label }}</span>
                        <i
                          class="el-icon-arrow-down el-icon--right"
                          style="color: #004caa"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div
                          v-show="
                            !limits_of_authority_disable(
                              'CONFIG_ITEM_EDIT_DELETE'
                            )
                          "
                          class="version-add"
                          style="
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                            color: #30648f;
                            margin: 4px 0;
                          "
                          @click="addControlMethodDialogShow"
                        >
                          <i class="iconfont icon-add"></i>
                        </div>
                        <el-dropdown-item>
                          <el-checkbox
                            v-model="table1CheckcontolAll"
                            :indeterminate="table1CheckcontolIsIndeterminate"
                            @change="table1CheckcontolHandleCheckAllChange"
                            @click.native.stop
                            >{{ $t("test.export.all") }}</el-checkbox
                          ></el-dropdown-item
                        >
                        <el-checkbox-group
                          v-model="table1CheckcontolList"
                          style="max-height: 300px; overflow-y: scroll"
                          @change="table1CheckcontolHandleCheckChange"
                        >
                          <el-dropdown-item
                            v-for="(
                              control, controlindex
                            ) in controlMethodOptions"
                            :key="controlindex"
                            ><div style="position: relative">
                              <el-checkbox
                                :label="control.controlMethodId"
                                @click.native.stop
                              >
                                <el-tooltip
                                  v-ak-tooltip-auto-show
                                  class="item"
                                  effect="dark"
                                  :content="control.name"
                                >
                                  <span
                                    style="
                                      display: inline-block;
                                      width: 100px;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      vertical-align: bottom;
                                      margin-right: 10px;
                                    "
                                  >
                                    {{ control.name }}
                                  </span>
                                </el-tooltip></el-checkbox
                              >

                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && control.name !== 'null'
                                "
                                class="iconfont icon-edit"
                                style="
                                  color: #004caa;
                                  cursor: pointer;
                                  right: 20px;
                                  top: 2px;
                                "
                                @click.stop="editControlMethodDialog(control)"
                              />
                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && control.name !== 'null'
                                "
                                class="el-icon-delete"
                                style="
                                  cursor: pointer;
                                  color: #ff4823;
                                  font-size: 18px;
                                  right: 0;
                                  top: 0;
                                "
                                @click.stop="deleteControlMethod(control)"
                              ></i>
                            </div>
                          </el-dropdown-item>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div
                    v-else-if="
                      item.type === 'sys' && item.prop === 'fileArchiveId'
                    "
                  >
                    <span
                      >{{ item.label }}

                      <span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{
                              $t(
                                "configurationManagement.tableFields.versionTips"
                              )
                            }}
                          </div>
                          <i
                            id="dddd8"
                            class="iconfont icon-help"
                            style="
                              font-size: 14px;
                              font-weight: normal;
                              cursor: pointer;
                            "
                          ></i> </el-tooltip
                      ></span>
                    </span>
                  </div>
                  <div v-else-if="item.type === 'sys'">
                    <span>{{ item.label }}</span>
                  </div>
                  <div v-else-if="item.type === 'cus'">
                    <span>{{ item.label }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div
                    v-if="item.type === 'sys' && item.prop === 'processAreaId'"
                  >
                    <el-select
                      v-model="scope.row.processAreaId"
                      size="mini"
                      clearable
                      @change="editFileConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="process in processOptions"
                        v-show="process.name !== 'null'"
                        :key="process.processAreaId"
                        :label="process.name"
                        :value="process.processAreaId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'description'"
                  >
                    <el-input
                      v-model="scope.row.description"
                      size="mini"
                      @blur="editFileConfigItem(scope.row)"
                    ></el-input>
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'key'"
                    style="
                      cursor: pointer;
                      font-weight: bold;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      color: rgb(255, 195, 0);
                    "
                    @click="jumpToFile(scope.row.fileItemDto.key)"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        all_file_type[scope.row.fileItemDto.fileTypeId].name
                      "
                      placement="top"
                    >
                      <span>
                        <i
                          v-if="
                            all_file_type[scope.row.fileItemDto.fileTypeId]
                              .icon !== '-1'
                          "
                          class="iconfont"
                          :class="
                            'icon-a-' +
                            all_file_type[scope.row.fileItemDto.fileTypeId].icon
                          "
                          style="
                            font-size: 20px;
                            color: rgb(48, 100, 143);
                            vertical-align: middle;
                            margin-right: 6px;
                            font-weight: normal;
                          "
                        ></i>
                        <span
                          v-else
                          style="
                            font-weight: 700;
                            color: rgb(38, 50, 129);
                            font-size: 16px;
                            margin-right: 6px;
                            font-weight: normal;
                          "
                          >{{
                            all_file_type[
                              scope.row.fileItemDto.fileTypeId
                            ].name.slice(0, 1)
                          }}</span
                        >
                      </span>
                    </el-tooltip>
                    {{ scope.row.fileItemDto.key }}
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'fileName'"
                    style="cursor: pointer"
                    @click="jumpToFile(scope.row.fileItemDto.key)"
                  >
                    {{ scope.row.fileItemDto.name }}
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'fileArchiveId'"
                  >
                    <el-select
                      v-model="scope.row.fileArchiveId"
                      size="mini"
                      clearable
                      @change="editFileConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="fileArchive in scope.row.fileItemDto
                          .fileArchiveDtoList"
                        :key="fileArchive.archiveId"
                        :label="fileArchive.archiveName"
                        :value="fileArchive.archiveId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'fileStatus'">
                    <status-tag
                      v-model="status[scope.row.fileItemDto.status].name"
                      :style_catogry="'plain'"
                      :status_type="
                        status[scope.row.fileItemDto.status].statusCategory
                      "
                      :size="'small'"
                    >
                    </status-tag>
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'reviewStatus'"
                  >
                    <span
                      class="pingshen-status-tag"
                      :style="{
                        borderStyle: 'solid',
                        color: getReviewingTheme(scope.row.reviewStatus),
                      }"
                    >
                      {{ getReviewingStatusText(scope.row.reviewStatus) }}
                    </span>
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'assignee'">
                    {{ matchUserNickname(scope.row.fileItemDto.assignee) }}
                  </div>
                  <div
                    v-if="
                      item.type === 'sys' && item.prop === 'controlMethodId'
                    "
                  >
                    <el-select
                      v-model="scope.row.controlMethodId"
                      size="mini"
                      clearable
                      @change="editFileConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="controlMethod in controlMethodOptions"
                        v-show="controlMethod.name !== 'null'"
                        :key="controlMethod.controlMethodId"
                        :label="controlMethod.name"
                        :value="controlMethod.controlMethodId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'updatedBy'">
                    {{ matchUserNickname(scope.row.updatedBy) }}
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'updatedAt'">
                    {{
                      new Date(scope.row.updatedAt).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      )
                    }}
                  </div>
                  <div
                    v-if="item.type === 'cus' && item.fieldType === 'TEXTAREA'"
                  >
                    <el-input
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      type="textarea"
                      autosize
                      rows="1"
                      @blur="editFileConfigItem(scope.row)"
                    >
                    </el-input>
                  </div>
                  <div
                    v-if="item.type === 'cus' && item.fieldType === 'SELECT'"
                  >
                    <el-select
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      size="mini"
                      clearable
                      @change="editFileConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="option in table1CusHeaderListMap[item.prop]
                          .extraData"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="
                      item.type === 'cus' && item.fieldType === 'SELECT_MULTI'
                    "
                  >
                    <el-select
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      multiple
                      collapse-tags
                      clearable
                      size="mini"
                      @change="editFileConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="option in table1CusHeaderListMap[item.prop]
                          .extraData"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <i
                    v-show="
                      !limits_of_authority_disable('CONFIG_ITEM_EDIT_DELETE')
                    "
                    class="iconfont icon-baseline-delete"
                    style="cursor: pointer; color: #f56c6c"
                    @click="deleteFileConfigItem(scope.$index, scope.row)"
                  >
                  </i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </collapse>
      </div>
      <div class="scroll_body" style="margin-top: 10px">
        <!-- 添加节点 -->
        <div class="add_node_wrap">
          <div id="quick_select">
            <el-select
              v-model="fileSelect2"
              multiple
              size="small"
              :placeholder="$t('configurationManagement.addPlaceHolder2')"
              filterable
              clearable
              remote
              style="margin-right: 10px; width: 288px; overflow: hidden"
              :remote-method="getKnowledgeBaseOptions"
              :popper-append-to-body="true"
              collapse-tags
              :loading="searchLoading"
              popper-class="select-icon-height-auto-class"
              reserve-keyword
            >
              <el-option
                v-for="item in knowledgeBaseOptions"
                :key="item.documentId"
                :value="item.documentId"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </div>
          <span>
            <el-button
              v-limits-of-authority="'CONFIG_ITEM_EDIT_DELETE'"
              type="primary"
              :disabled="limits_of_authority_disable('CONFIG_ITEM_EDIT_DELETE')"
              style="vertical-align: text-bottom"
              @click="addKnowledgeBaseViewConfigItem"
            >
              {{ $t("btn.addBtn") }}</el-button
            >
          </span>
        </div>
        <collapse class="table_collapse">
          <template slot="title">
            {{ $t("configurationManagement.block2") }}
          </template>

          <template slot="pagination">
            <el-pagination
              :page-size="page2Obj.pageSize"
              :current-page.sync="page2Obj.currentPage"
              class="header-pagination"
              :background="false"
              small
              layout="prev, pager, next, total"
              :total="page2Obj.total"
              @current-change="table2pageChange"
            >
            </el-pagination>
          </template>
          <div class="baseline-nodes">
            <el-table
              :data="tableData2"
              :header-cell-style="{
                color: 'rgba(84, 85, 86, 1)',
                fontWeight: 'blod',
                fontSize: '14px',
                backgroundColor: 'transparent',
              }"
              height="31vh"
            >
              <el-table-column width="50px">
                <template slot="header" slot-scope="scope">
                  <el-dropdown
                    trigger="click"
                    style="cursor: pointer"
                    class="notDrag"
                    placement="bottom-start"
                  >
                    <span
                      class="word_button"
                      style="display: flex; align-items: center"
                    >
                      <i
                        class="notDrag iconfont icon-a-31shezhi"
                        style="font-size: 20px; margin-right: 5px"
                      />
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      :append-to-body="true"
                      class="notDrag"
                    >
                      <div
                        v-show="
                          !limits_of_authority_disable(
                            'CONFIG_ITEM_EDIT_DELETE'
                          )
                        "
                        class="version-add"
                        style="
                          display: flex;
                          justify-content: center;
                          cursor: pointer;
                          color: #30648f;
                          margin: 4px 0;
                        "
                        @click="addCustomFieldsDialogShow"
                      >
                        <i class="iconfont icon-add"></i>
                      </div>
                      <el-checkbox-group
                        v-model="showColumn2"
                        class="select"
                        style="max-height: 300px; overflow-y: scroll"
                        @change="changeShowFields2"
                      >
                        <div
                          v-for="type in allFields2"
                          :key="type.prop"
                          style="position: relative"
                        >
                          <el-checkbox :label="type.label" class="checkbox">
                            <el-tooltip
                              v-ak-tooltip-auto-show
                              class="item"
                              effect="dark"
                              :content="type.label"
                            >
                              <span
                                style="
                                  display: inline-block;
                                  width: 100px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  vertical-align: bottom;
                                  margin-right: 10px;
                                "
                              >
                                {{ type.label }}
                              </span>
                            </el-tooltip>
                          </el-checkbox>
                          <i
                            v-if="type.type == 'cus'"
                            v-show="
                              !limits_of_authority_disable(
                                'CONFIG_ITEM_EDIT_DELETE'
                              )
                            "
                            class="iconfont icon-edit"
                            style="
                              color: #004caa;
                              position: absolute;
                              cursor: pointer;
                              right: 20px;
                              top: 2px;
                            "
                            @click.stop="editFields(type)"
                          />
                          <i
                            v-if="type.type == 'cus'"
                            v-show="
                              !limits_of_authority_disable(
                                'CONFIG_ITEM_EDIT_DELETE'
                              )
                            "
                            class="el-icon-delete"
                            style="
                              cursor: pointer;
                              color: #ff4823;
                              font-size: 18px;
                              position: absolute;
                              right: 0;
                              top: 0;
                            "
                            @click.stop="deleteFields(type)"
                          ></i>
                        </div>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in table2HeaderList"
                :key="index"
                show-overflow-tooltip
                :label="item.label"
                width="200px"
              >
                <template slot="header" slot-scope="scope">
                  <div
                    v-if="item.type === 'sys' && item.prop === 'processAreaId'"
                  >
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        :class="{
                          'checked-label':
                            table2CheckprocessAreaList.length > 0,
                        }"
                      >
                        <span>{{ item.label }}</span>
                        <i
                          class="el-icon-arrow-down el-icon--right"
                          style="color: #004caa"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div
                          v-show="
                            !limits_of_authority_disable(
                              'CONFIG_ITEM_EDIT_DELETE'
                            )
                          "
                          class="version-add"
                          style="
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                            color: #30648f;
                            margin: 4px 0;
                          "
                          @click="addprocessAreaDialogShow"
                        >
                          <i class="iconfont icon-add"></i>
                        </div>
                        <el-dropdown-item>
                          <el-checkbox
                            v-model="table2CheckprocessAreaAll"
                            :indeterminate="
                              table2CheckprocessAreaIsIndeterminate
                            "
                            @change="table2CheckprocessAreaHandleCheckAllChange"
                            @click.native.stop
                            >{{ $t("test.export.all") }}</el-checkbox
                          ></el-dropdown-item
                        >
                        <el-checkbox-group
                          v-model="table2CheckprocessAreaList"
                          style="max-height: 300px; overflow-y: scroll"
                          @change="table2CheckprocessAreaHandleCheckChange"
                        >
                          <el-dropdown-item
                            v-for="(process, processindex) in processOptions"
                            :key="processindex"
                            ><div style="position: relative">
                              <el-checkbox
                                :label="process.processAreaId"
                                @click.native.stop
                              >
                                <el-tooltip
                                  v-ak-tooltip-auto-show
                                  class="item"
                                  effect="dark"
                                  :content="process.name"
                                >
                                  <span
                                    style="
                                      display: inline-block;
                                      width: 100px;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      vertical-align: bottom;
                                      margin-right: 10px;
                                    "
                                  >
                                    {{ process.name }}
                                  </span>
                                </el-tooltip></el-checkbox
                              >

                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && process.name !== 'null'
                                "
                                class="iconfont icon-edit"
                                style="
                                  color: #004caa;
                                  cursor: pointer;
                                  right: 20px;
                                  top: 2px;
                                "
                                @click.stop="editProcessAreaDialog(process)"
                              />
                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && process.name !== 'null'
                                "
                                class="el-icon-delete"
                                style="
                                  cursor: pointer;
                                  color: #ff4823;
                                  font-size: 18px;
                                  right: 0;
                                  top: 0;
                                "
                                @click.stop="deleteProcessArea(process)"
                              ></i>
                            </div>
                          </el-dropdown-item>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div
                    v-else-if="
                      item.type === 'sys' && item.prop === 'controlMethodId'
                    "
                  >
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        :class="{
                          'checked-label': table2CheckcontolList.length > 0,
                        }"
                      >
                        <span>{{ item.label }}</span>
                        <i
                          class="el-icon-arrow-down el-icon--right"
                          style="color: #004caa"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div
                          v-show="
                            !limits_of_authority_disable(
                              'CONFIG_ITEM_EDIT_DELETE'
                            )
                          "
                          class="version-add"
                          style="
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                            color: #30648f;
                            margin: 4px 0;
                          "
                          @click="addControlMethodDialogShow"
                        >
                          <i class="iconfont icon-add"></i>
                        </div>
                        <el-dropdown-item>
                          <el-checkbox
                            v-model="table2CheckcontolAll"
                            :indeterminate="table2CheckcontolIsIndeterminate"
                            @change="table2CheckcontolHandleCheckAllChange"
                            @click.native.stop
                            >{{ $t("test.export.all") }}</el-checkbox
                          ></el-dropdown-item
                        >
                        <el-checkbox-group
                          v-model="table2CheckcontolList"
                          style="max-height: 300px; overflow-y: scroll"
                          @change="table2CheckcontolHandleCheckChange"
                        >
                          <el-dropdown-item
                            v-for="(
                              control, controlindex
                            ) in controlMethodOptions"
                            :key="controlindex"
                            ><div style="position: relative">
                              <el-checkbox
                                :label="control.controlMethodId"
                                @click.native.stop
                              >
                                <el-tooltip
                                  v-ak-tooltip-auto-show
                                  class="item"
                                  effect="dark"
                                  :content="control.name"
                                >
                                  <span
                                    style="
                                      display: inline-block;
                                      width: 100px;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      vertical-align: bottom;
                                      margin-right: 10px;
                                    "
                                  >
                                    {{ control.name }}
                                  </span>
                                </el-tooltip></el-checkbox
                              >

                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && control.name !== 'null'
                                "
                                class="iconfont icon-edit"
                                style="
                                  color: #004caa;
                                  cursor: pointer;
                                  right: 20px;
                                  top: 2px;
                                "
                                @click.stop="editControlMethodDialog(control)"
                              />
                              <i
                                v-show="
                                  !limits_of_authority_disable(
                                    'CONFIG_ITEM_EDIT_DELETE'
                                  ) && control.name !== 'null'
                                "
                                class="el-icon-delete"
                                style="
                                  cursor: pointer;
                                  color: #ff4823;
                                  font-size: 18px;
                                  right: 0;
                                  top: 0;
                                "
                                @click.stop="deleteControlMethod(control)"
                              ></i>
                            </div>
                          </el-dropdown-item>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div v-else-if="item.type === 'sys'">
                    <span>{{ item.label }}</span>
                  </div>
                  <div v-else-if="item.type === 'cus'">
                    <span>{{ item.label }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div
                    v-if="item.type === 'sys' && item.prop === 'processAreaId'"
                  >
                    <el-select
                      v-model="scope.row.processAreaId"
                      size="mini"
                      clearable
                      @change="editKnowledgeBaseViewConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="process in processOptions"
                        v-show="process.name !== 'null'"
                        :key="process.processAreaId"
                        :label="process.name"
                        :value="process.processAreaId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="item.type === 'sys' && item.prop === 'description'"
                  >
                    <el-input
                      v-model="scope.row.description"
                      size="mini"
                      @blur="editKnowledgeBaseViewConfigItem(scope.row)"
                    ></el-input>
                  </div>
                  <div
                    v-if="
                      item.type === 'sys' && item.prop === 'knowledgeBaseName'
                    "
                    style="cursor: pointer"
                    @click="jumpToknowledge(scope.row.documentDto)"
                  >
                    {{ scope.row.documentDto.label }}
                  </div>
                  <div
                    v-if="
                      item.type === 'sys' && item.prop === 'controlMethodId'
                    "
                  >
                    <el-select
                      v-model="scope.row.controlMethodId"
                      size="mini"
                      clearable
                      @change="editKnowledgeBaseViewConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="controlMethod in controlMethodOptions"
                        v-show="controlMethod.name !== 'null'"
                        :key="controlMethod.controlMethodId"
                        :label="controlMethod.name"
                        :value="controlMethod.controlMethodId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'updatedBy'">
                    {{ matchUserNickname(scope.row.updatedBy) }}
                  </div>
                  <div v-if="item.type === 'sys' && item.prop === 'updatedAt'">
                    {{
                      new Date(scope.row.updatedAt).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      )
                    }}
                  </div>
                  <div
                    v-if="item.type === 'cus' && item.fieldType === 'TEXTAREA'"
                  >
                    <el-input
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      type="textarea"
                      autosize
                      rows="1"
                      @blur="editKnowledgeBaseViewConfigItem(scope.row)"
                    >
                    </el-input>
                  </div>
                  <div
                    v-if="item.type === 'cus' && item.fieldType === 'SELECT'"
                  >
                    <el-select
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      size="mini"
                      clearable
                      @change="editKnowledgeBaseViewConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="option in table1CusHeaderListMap[item.prop]
                          .extraData"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="
                      item.type === 'cus' && item.fieldType === 'SELECT_MULTI'
                    "
                  >
                    <el-select
                      v-model="scope.row.tempExtraFieldList[item.prop]"
                      multiple
                      collapse-tags
                      size="mini"
                      clearable
                      @change="editKnowledgeBaseViewConfigItem(scope.row)"
                    >
                      <el-option
                        v-for="option in table1CusHeaderListMap[item.prop]
                          .extraData"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <i
                    v-show="
                      !limits_of_authority_disable('CONFIG_ITEM_EDIT_DELETE')
                    "
                    class="iconfont icon-baseline-delete"
                    style="cursor: pointer; color: #f56c6c"
                    @click="
                      deleteKnowledgeBaseViewConfigItem(scope.$index, scope.row)
                    "
                  >
                  </i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </collapse>
      </div>
    </div>
    <el-dialog
      :visible.sync="addColDialog"
      width="25%"
      top="25vh"
      :title="$t('configurationManagement.addCol.topic')"
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="create_dashboard"
    >
      <el-form
        ref="addColForm"
        label-width="80px"
        :model="addColData"
        label-position="left"
        :rules="addColRules"
      >
        <el-form-item
          prop="name"
          :label="$t('configurationManagement.addCol.title')"
        >
          <el-input v-model="addColData.name"> </el-input>
        </el-form-item>
        <el-form-item
          prop="type"
          :label="$t('configurationManagement.addCol.type')"
        >
          <el-select v-model="addColData.type" style="width: 100%">
            <el-option
              :label="$t('configurationManagement.fieldsType.select')"
              value="SELECT"
            >
            </el-option>
            <el-option
              :label="$t('configurationManagement.fieldsType.MultiSelect')"
              value="SELECT_MULTI"
            >
            </el-option>
            <el-option
              :label="$t('configurationManagement.fieldsType.TextArea')"
              value="TEXTAREA"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        v-if="
          addColData.type === 'SELECT' || addColData.type === 'SELECT_MULTI'
        "
        class="cus_options_class"
      >
        <div style="margin-top: 4px; width: 70%">
          <el-input
            v-model="cusInputValue"
            size="small"
            :placeholder="
              $t('fieldLab.fieldTable.options.editForm.placeholder')
            "
            @keydown.enter.native="addCusOptions"
          ></el-input>
        </div>
        <div style="cursor: pointer; width: 30%; padding-left: 10px">
          <el-button size="small" type="primary" @click="addCusOptions">{{
            $t("btn.addBtn")
          }}</el-button>
        </div>
        <div style="margin: 10px 0 24px 0; width: 100%; text-align: left">
          <div
            v-for="(item, index) in addColData.extraData"
            :key="index"
            class="check_box_wrap"
          >
            <div class="check_box_wrap_delete" @click="deleteCusOptions(index)">
              x
            </div>
            <el-popover placement="top-start" trigger="hover">
              <template slot="reference">
                {{ item.label }}
              </template>
            </el-popover>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="addColDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="addCustomField"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="addProcessAreaDialog"
      width="25%"
      top="25vh"
      :title="
        addProcessAreaData.processAreaId
          ? $t('configurationManagement.addProcessArea.title2')
          : $t('configurationManagement.addProcessArea.title')
      "
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="create_dashboard"
    >
      <el-input v-model="addProcessAreaData.name"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="addProcessAreaDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="addProcessArea"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="addControlMethodDialog"
      width="25%"
      top="25vh"
      :title="
        addControlMethodData.controlMethodId
          ? $t('configurationManagement.addControlMethod.title2')
          : $t('configurationManagement.addControlMethod.title')
      "
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="create_dashboard"
    >
      <el-input v-model="addControlMethodData.name"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="addControlMethodDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="addControlMethod"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="copyDialog"
      width="25%"
      top="25vh"
      :title="
        copyChartForm.configItemId
          ? $t('btn.editBtn')
          : $t('configurationManagement.copyConfiguration')
      "
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="create_dashboard"
    >
      <el-form
        ref="addDatachartForm"
        label-width="150px"
        :model="copyChartForm"
        label-position="left"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          :label="$t('configurationManagement.addconfiguration.name')"
        >
          <el-input v-model="copyChartForm.name"> </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('configurationManagement.addconfiguration.desc')"
        >
          <el-input v-model="copyChartForm.description"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="copyDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" class="button-confirm" @click="copyFn">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 存档弹窗 -->
    <el-dialog
      :visible.sync="archive_config_view_dialog"
      :title="$t('nodeDetail.activity.tab.version')"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-table
        :data="archiveConfigData"
        style="width: 100%"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
      >
        <!-- 基线版本号 -->
        <el-table-column prop="name" :label="$t('baseline.archive.version')">
          <template slot-scope="scope">
            <el-link
              :href="scope.row.excelUrl"
              style="font-weight: 700; color: #409eff"
            >
              {{ scope.row.name }}
            </el-link>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          :label="$t('baseline.archive.remark')"
          width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <!-- show-overflow-tooltip="true" 内容过长提示 -->
        <!-- 存档人 -->
        <el-table-column prop="createdBy" :label="$t('baseline.archive.user')">
          <template slot-scope="scope">
            {{ matchUserNickname(scope.row.createdBy) }}
          </template>
        </el-table-column>
        <!-- 存档时间 -->
        <el-table-column prop="createdAt" :label="$t('baseline.archive.time')">
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <el-pagination
          layout="prev, pager, next, total"
          :total="archivePageObj.total"
          :page-size="archivePageObj.pageSize"
          :current-page="archivePageObj.currentPage"
          @current-change="changePageArchiveConfigData"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      width="25%"
      top="25vh"
      custom-class="archive_baseline"
      :show-close="true"
      :title="$t('baseline.archive.title3')"
      :visible.sync="archive_config_dialog"
      :close-on-click-modal="false"
    >
      <!-- 存档表单 -->
      <el-form
        ref="archiveForm"
        label-width="120px"
        :rules="rules"
        :model="archive_form"
        label-position="left"
      >
        <!-- 版本 版本号 --必填 -->
        <el-form-item prop="name">
          <template slot="label">
            <span>
              {{ $t("baseline.archive.version") }}
            </span>
          </template>
          <el-input v-model="archive_form.name"></el-input>
        </el-form-item>
        <!-- 备注 --选填 -->
        <el-form-item prop="remark">
          <template slot="label">
            <span>
              {{ $t("baseline.archive.remark") }}
            </span>
          </template>
          <el-input
            v-model="archive_form.remark"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 3 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="archive_config_dialog = false">
          {{ $t("baseline.archive.cancel") }}
        </el-button>
        <el-button
          class="button-confirm"
          type="primary"
          :loading="archiveLoading"
          @click="onsubmit()"
        >
          {{ $t("baseline.archive.submit") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteConfiguration,
  editConfiguration,
  addFileConfigItem,
  getFileConfigItem,
  deleteFileConfigItem,
  editFileConfigItem,
  getCustomField,
  addCustomField,
  deleteCustomField,
  editCustomField,
  getKnowledgeBaseViewConfigItem,
  addKnowledgeBaseViewConfigItem,
  editKnowledgeBaseViewConfigItem,
  deleteKnowledgeBaseViewConfigItem,
  documentsFuzzy,
  getProcessArea,
  editProcessArea,
  addProcessArea,
  deletetProcessArea,
  getControlMethod,
  editControlMethod,
  addControlMethod,
  deletetControlMethod,
  copyConfig,
  postArchive,
  getArchive,
} from "@/network/configurationManagement/index.js";
import { get_file_List } from "@/network/home/index.js";
import { mapGetters } from "vuex";
import Collapse from "./Collapse.vue";
import selectIcon from "@/components/selectIcon";
import StatusTag from "@/components/statusTag";
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  components: {
    Collapse,
    selectIcon,
    StatusTag,
  },
  props: {
    configurationOptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(["status", "all_file_type"]),
  },
  data() {
    return {
      currentConfigurationId: "",
      currentConfigObj: {},
      copyDialog: false,
      addConfigurationDialog: false,
      isEdit: false,
      isAutoSave: false,
      activeNames: [],
      page1Obj: {
        pageSize: 8,
        currentPage: 1,
        total: 0,
      },
      page2Obj: { pageSize: 8, currentPage: 1, total: 0 },
      tableData1: [],
      tableData2: [],
      fileSelect1: [],
      fileSelect2: [],
      fileOptions: [],
      knowledgeBaseOptions: [],
      searchLoading: false,
      showColumn: [],
      showColumn2: [],
      table1SysHeaderList: [
        {
          label: this.$t("configurationManagement.tableFields.ProcessArea"),
          prop: "processAreaId",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.fileDescription"),
          prop: "description",
          type: "sys",
        },
        {
          label: "Key",
          prop: "key",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.fileName"),
          prop: "fileName",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.Version"),
          prop: "fileArchiveId",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.FileStatus"),
          prop: "fileStatus",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.ReviewStatus"),
          prop: "reviewStatus",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.assignee"),
          prop: "assignee",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.Controlmethod"),
          prop: "controlMethodId",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.operator"),
          prop: "updatedBy",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.OperatingTime"),
          prop: "updatedAt",
          type: "sys",
        },
      ],
      table1CusHeaderList: [],
      table1CusHeaderListMap: {},
      table1HeaderList: [],
      allFields: [],
      allFields2: [],
      table2SysHeaderList: [
        {
          label: this.$t("configurationManagement.tableFields.ProcessArea"),
          prop: "processAreaId",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.pageDescription"),
          prop: "description",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.pageName"),
          prop: "knowledgeBaseName",
          type: "sys",
        },
        // {
        //   label: "版本号",
        //   prop: "1",
        // },
        {
          label: this.$t("configurationManagement.tableFields.Controlmethod"),
          prop: "controlMethodId",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.operator"),
          prop: "updatedBy",
          type: "sys",
        },
        {
          label: this.$t("configurationManagement.tableFields.OperatingTime"),
          prop: "updatedAt",
          type: "sys",
        },
      ],
      table2HeaderList: [],
      addColDialog: false,
      addColData: {
        name: "",
        type: "",
        extraData: [],
      },
      addColRules: {
        name: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      cusInputValue: "",
      addProcessAreaDialog: false,
      addControlMethodDialog: false,
      addProcessAreaData: {
        name: "",
      },
      addControlMethodData: {
        name: "",
      },
      processOptions: [],
      controlMethodOptions: [],
      copyChartForm: {
        name: "",
        description: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      table1CheckcontolList: [],
      table1CheckcontolAll: false,
      table1CheckcontolIsIndeterminate: false,
      table1CheckprocessAreaList: [],
      table1CheckprocessAreaAll: false,
      table1CheckprocessAreaIsIndeterminate: false,
      table2CheckcontolList: [],
      table2CheckcontolAll: false,
      table2CheckcontolIsIndeterminate: false,
      table2CheckprocessAreaList: [],
      table2CheckprocessAreaAll: false,
      table2CheckprocessAreaIsIndeterminate: false,
      // 存档相关
      archive_config_view_dialog: false,
      archive_config_dialog: false,
      archiveLoading: false,
      archiveConfigData: [],
      archivePageObj: { pageSize: 8, currentPage: 1, total: 0 },
      archive_form: {
        name: "",
        remark: "",
      },
      // 校验规则
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.configurationOptions.length) {
      this.selectConfig(this.configurationOptions[0].configItemId);
    }
    this.getCustomField();
    this.getProcessArea();
    this.getControlMethod();
  },
  methods: {
    limits_of_authority_disable,
    getArchiveData() {
      this.archivePageObj.currentPage = 1;
      this.archive_config_view_dialog = true;
      this.getArchive();
    },
    getArchive() {
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: {
          pageNumber: this.archivePageObj.currentPage - 1,
          pageSize: this.archivePageObj.pageSize,
        },
      };
      getArchive(params).then((res) => {
        this.archiveConfigData = res.content;
        this.archivePageObj.total = res.totalElements;
      });
    },
    // 表单清空
    onreset(formName) {
      this.$refs[formName].resetFields();
    },
    onsubmit() {
      this.$refs["archiveForm"].validate((vaild) => {
        if (vaild) {
          this.archiveLoading = true;
          const params = {
            projectId: this.get_pid(),
            configItemId: this.currentConfigurationId,
            data: {
              name: this.archive_form.name,
              remark: this.archive_form.remark,
            },
          };
          postArchive(params)
            .then((res) => {
              this.$message({
                type: "success",
                message: this.$t("baseline.message.archiveSuccess"),
                duration: "5000",
              });
              this.archiveLoading = false;
              this.archive_config_dialog = false;
              this.onreset("archiveForm");
            })
            .catch((err) => {
              this.archiveLoading = false;
            });
        }
      });
    },
    changePageArchiveConfigData(current) {
      this.archivePageObj.currentPage = current;
      this.getArchive();
    },
    table1CheckprocessAreaHandleCheckAllChange(val) {
      this.table1CheckprocessAreaList = [];
      if (val) {
        this.processOptions.forEach((item) => {
          this.table1CheckprocessAreaList.push(item.processAreaId);
        });
        this.table1CheckprocessAreaAll = true;
      }
      this.table1CheckprocessAreaIsIndeterminate = false;
      this.page1Obj.currentPage = 1;
      this.getFileConfigItem();
    },
    table1CheckprocessAreaHandleCheckChange(value) {
      let checkedCount = value.length;
      this.table1CheckprocessAreaAll =
        checkedCount === this.processOptions.length;
      this.table1CheckprocessAreaIsIndeterminate =
        checkedCount > 0 && checkedCount < this.processOptions.length;
      this.page1Obj.currentPage = 1;
      this.getFileConfigItem();
    },
    table1CheckcontolHandleCheckAllChange(val) {
      this.table1CheckcontolList = [];
      if (val) {
        this.controlMethodOptions.forEach((item) => {
          this.table1CheckcontolList.push(item.controlMethodId);
        });
        this.table1CheckcontolAll = true;
      }
      this.table1CheckcontolIsIndeterminate = false;
      this.page1Obj.currentPage = 1;
      this.getFileConfigItem();
    },
    table1CheckcontolHandleCheckChange(value) {
      let checkedCount = value.length;
      this.table1CheckcontolAll =
        checkedCount === this.controlMethodOptions.length;
      this.table1CheckcontolIsIndeterminate =
        checkedCount > 0 && checkedCount < this.controlMethodOptions.length;
      this.page1Obj.currentPage = 1;
      this.getFileConfigItem();
    },
    table2CheckprocessAreaHandleCheckAllChange(val) {
      this.table2CheckprocessAreaList = [];
      if (val) {
        this.processOptions.forEach((item) => {
          this.table2CheckprocessAreaList.push(item.processAreaId);
        });
        this.table2CheckprocessAreaAll = true;
      }
      this.table2CheckprocessAreaIsIndeterminate = false;
      this.page2Obj.currentPage = 1;
      this.gettKnowledgeBaseItem();
    },
    table2CheckprocessAreaHandleCheckChange(value) {
      let checkedCount = value.length;
      this.table2CheckprocessAreaAll =
        checkedCount === this.processOptions.length;
      this.table2CheckprocessAreaIsIndeterminate =
        checkedCount > 0 && checkedCount < this.processOptions.length;
      this.page2Obj.currentPage = 1;
      this.gettKnowledgeBaseItem();
    },
    table2CheckcontolHandleCheckAllChange(val) {
      this.table2CheckcontolList = [];
      if (val) {
        this.controlMethodOptions.forEach((item) => {
          this.table2CheckcontolList.push(item.controlMethodId);
        });
        this.table2CheckcontolAll = true;
      }
      this.table2CheckcontolIsIndeterminate = false;
      this.page2Obj.currentPage = 1;
      this.gettKnowledgeBaseItem();
    },
    table2CheckcontolHandleCheckChange(value) {
      let checkedCount = value.length;
      this.table2CheckcontolAll =
        checkedCount === this.controlMethodOptions.length;
      this.table2CheckcontolIsIndeterminate =
        checkedCount > 0 && checkedCount < this.controlMethodOptions.length;
      this.page2Obj.currentPage = 1;
      this.gettKnowledgeBaseItem();
    },
    getProcessArea() {
      const params = {
        projectId: this.get_pid(),
      };
      getProcessArea(params).then((res) => {
        this.processOptions = res;
      });
    },
    getControlMethod() {
      const params = {
        projectId: this.get_pid(),
      };
      getControlMethod(params).then((res) => {
        this.controlMethodOptions = res;
      });
    },
    addprocessAreaDialogShow() {
      this.addProcessAreaDialog = true;
      this.addProcessAreaData = {
        name: "",
      };
    },
    addControlMethodDialogShow() {
      this.addControlMethodDialog = true;
      this.addControlMethodData = {
        name: "",
      };
    },
    addProcessArea() {
      if (this.addProcessAreaData.processAreaId) {
        const params = {
          projectId: this.get_pid(),
          processAreaId: this.addProcessAreaData.processAreaId,
          data: this.addProcessAreaData,
        };
        editProcessArea(params).then((res) => {
          this.getProcessArea();
          this.addProcessAreaDialog = false;
        });
      } else {
        const params = {
          projectId: this.get_pid(),
          data: {
            name: this.addProcessAreaData.name,
          },
        };
        addProcessArea(params).then((res) => {
          this.getProcessArea();
          this.addProcessAreaDialog = false;
        });
      }
    },
    addControlMethod() {
      if (this.addControlMethodData.controlMethodId) {
        const params = {
          projectId: this.get_pid(),
          controlMethodId: this.addControlMethodData.controlMethodId,
          data: this.addControlMethodData,
        };
        editControlMethod(params).then((res) => {
          this.getControlMethod();
          this.addControlMethodDialog = false;
        });
      } else {
        const params = {
          projectId: this.get_pid(),
          data: {
            name: this.addControlMethodData.name,
          },
        };
        addControlMethod(params).then((res) => {
          this.getControlMethod();
          this.addControlMethodDialog = false;
        });
      }
    },
    editProcessAreaDialog(process) {
      this.addProcessAreaDialog = true;
      this.addProcessAreaData = process;
    },
    editControlMethodDialog(control) {
      this.addControlMethodDialog = true;
      this.addControlMethodData = control;
    },
    deleteProcessArea(process) {
      const params = {
        projectId: this.get_pid(),
        processAreaId: process.processAreaId,
      };
      deletetProcessArea(params).then((res) => {
        this.getProcessArea();
        this.table1pageChange();
        this.table2pageChange();
      });
    },
    deleteControlMethod(control) {
      const params = {
        projectId: this.get_pid(),
        controlMethodId: control.controlMethodId,
      };
      deletetControlMethod(params).then((res) => {
        this.getControlMethod();
        this.table1pageChange();
        this.table2pageChange();
      });
    },
    addKnowledgeBaseViewConfigItem() {
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: this.fileSelect2,
      };
      addKnowledgeBaseViewConfigItem(params).then((res) => {
        this.gettKnowledgeBaseItem();
        this.fileSelect2 = [];
        this.knowledgeBaseOptions = [];
      });
    },
    deleteCusOptions(index) {
      this.addColData.extraData.splice(index, 1);
    },
    addCusOptions() {
      const obj = {
        label: this.cusInputValue,
        value: this.cusInputValue,
      };
      this.addColData.extraData.push(obj);
    },
    editFields(item) {
      this.addColData.name = item.label;
      this.addColData.type = item.fieldType;
      this.addColData.extraData = item.extraData;
      this.addColData.prop = item.prop;
      this.addColDialog = true;
    },
    deleteFields(item) {
      const params = {
        projectId: this.get_pid(),
        configItemFieldId: item.prop,
      };
      deleteCustomField(params).then((res) => {
        this.getCustomField();
      });
    },
    jumpToFile(key) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/myMind/${key}`,
      });
      window.open(href, "_blank");
    },
    jumpToknowledge(data) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/knowledge?documentId=${
          data.documentId
        }&title=${data.label}`,
      });
      window.open(href, "_blank");
    },
    // 匹配评审相关
    getReviewingStatusText(status) {
      const dict = {
        FILE_REVIEW_STATUS_NO_START: this.$t(
          "configurationManagement.reviewStatus.sts1"
        ),
        FILE_REVIEW_STATUS_REVIEWING: this.$t(
          "configurationManagement.reviewStatus.sts2"
        ),
        FILE_REVIEW_STATUS_DONE: this.$t(
          "configurationManagement.reviewStatus.sts3"
        ),
      };
      return dict[status];
    },
    getReviewingTheme(status) {
      const dict = {
        FILE_REVIEW_STATUS_NO_START: "rgb(42, 130, 228)",
        FILE_REVIEW_STATUS_REVIEWING: "rgb(67, 207, 124)",
        FILE_REVIEW_STATUS_DONE: "rgb(166, 166, 166)",
      };
      return dict[status];
    },
    addCustomField() {
      this.$refs.addColForm.validate((valid) => {
        if (valid) {
          if (this.addColData.prop) {
            const params = {
              projectId: this.get_pid(),
              configItemFieldId: this.addColData.prop,
              data: this.addColData,
            };
            editCustomField(params).then((res) => {
              this.addColDialog = false;
              this.getCustomField();
            });
          } else {
            const params = {
              projectId: this.get_pid(),
              data: this.addColData,
            };
            addCustomField(params).then((res) => {
              this.addColData = {
                name: "",
                type: "",
                extraData: [],
              };
              this.getCustomField();
              this.addColDialog = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    addCustomFieldsDialogShow() {
      this.addColDialog = true;
      this.addColData = {
        name: "",
        type: "",
        extraData: [],
      };
    },
    // 选择显示字段
    changeShowFields(value) {
      this.table1HeaderList = [];
      this.allFields.forEach((fields) => {
        if (value.indexOf(fields.label) !== -1) {
          this.$nextTick(() => {
            this.table1HeaderList.push(fields);
          });
        }
      });
    },
    changeShowFields2(value) {
      this.table2HeaderList = [];
      this.allFields2.forEach((fields) => {
        if (value.indexOf(fields.label) !== -1) {
          this.$nextTick(() => {
            this.table2HeaderList.push(fields);
          });
        }
      });
    },
    // 获取自定义字段
    getCustomField() {
      this.table1CusHeaderList = [];
      this.showColumn = [];
      this.showColumn2 = [];
      getCustomField({
        projectId: this.get_pid(),
      }).then((res) => {
        res.forEach((item) => {
          const obj = {
            label: item.name,
            prop: item.configItemFieldId,
            type: "cus",
            fieldType: item.type,
            extraData: item.extraData,
          };
          this.table1CusHeaderList.push(obj);
          this.table1CusHeaderListMap[item.configItemFieldId] = item;
          if (this.showColumn.indexOf(item.name) === -1) {
            this.showColumn.push(item.name);
          }
          if (this.showColumn2.indexOf(item.name) === -1) {
            this.showColumn2.push(item.name);
          }
        });
        this.table1SysHeaderList.forEach((item) => {
          if (this.showColumn.indexOf(item.label) === -1) {
            this.showColumn.push(item.label);
          }
        });
        this.table2SysHeaderList.forEach((item) => {
          if (this.showColumn2.indexOf(item.label) === -1) {
            this.showColumn2.push(item.label);
          }
        });
        this.table1HeaderList = [];
        this.table2HeaderList = [];
        this.allFields = [
          ...this.table1SysHeaderList,
          ...this.table1CusHeaderList,
        ];
        this.allFields2 = [
          ...this.table2SysHeaderList,
          ...this.table1CusHeaderList,
        ];
        this.$nextTick(() => {
          setTimeout(() => {
            this.changeShowFields(this.showColumn);
            this.changeShowFields2(this.showColumn2);
          }, 100);
        });
      });
    },
    // 修改文件配置项
    editFileConfigItem(row) {
      row.extraFieldList = [];
      Object.keys(row.tempExtraFieldList).forEach((key) => {
        if (this.table1CusHeaderListMap[key]) {
          const obj = {
            configItemFieldId: key,
            value: row.tempExtraFieldList[key],
            type: this.table1CusHeaderListMap[key].type,
          };
          row.extraFieldList.push(obj);
        }
      });
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        fileConfigItemId: row.fileConfigItemId,
        data: row,
      };
      editFileConfigItem(params).then((res) => {});
    },
    // 修改知识库配置项
    editKnowledgeBaseViewConfigItem(row) {
      row.extraFieldList = [];
      Object.keys(row.tempExtraFieldList).forEach((key) => {
        if (this.table1CusHeaderListMap[key]) {
          const obj = {
            configItemFieldId: key,
            value: row.tempExtraFieldList[key],
            type: this.table1CusHeaderListMap[key].type,
          };
          row.extraFieldList.push(obj);
        }
      });
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        knowledgeBaseViewConfigId: row.knowledgeBaseViewConfigId,
        data: row,
      };
      editKnowledgeBaseViewConfigItem(params).then((res) => {});
    },
    // 删除文件配置项
    deleteFileConfigItem(index, row) {
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        fileConfigItemId: row.fileConfigItemId,
      };
      deleteFileConfigItem(params).then((res) => {
        if (index === this.tableData1.length - 1) {
          this.page1Obj.currentPage > 1 ? this.page1Obj.currentPage-- : "";
        }
        this.getFileConfigItem();
      });
    },
    // 删除知识库配置项
    deleteKnowledgeBaseViewConfigItem(index, row) {
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        knowledgeBaseViewConfigId: row.knowledgeBaseViewConfigId,
      };
      deleteKnowledgeBaseViewConfigItem(params).then((res) => {
        if (index === this.tableData2.length - 1) {
          this.page2Obj.currentPage > 1 ? this.page2Obj.currentPage-- : "";
        }
        this.gettKnowledgeBaseItem();
      });
    },
    // 新增文件配置项
    addFileConfigItem() {
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: this.fileSelect1,
      };
      addFileConfigItem(params).then((res) => {
        this.getFileConfigItem();
        this.fileSelect1 = [];
      });
    },
    // 获取文件配置项
    getFileConfigItem() {
      this.tableData1 = [];
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: {
          pageNumber: this.page1Obj.currentPage - 1,
          pageSize: this.page1Obj.pageSize,
          processAreaIdList: this.table1CheckprocessAreaList,
          controlMethodIdList: this.table1CheckcontolList,
        },
      };
      getFileConfigItem(params).then((res) => {
        res.content.forEach((item) => {
          item.extraFieldList ? "" : (item.extraFieldList = []);
          item.tempExtraFieldList = {};
          item.extraFieldList.forEach((cus) => {
            item.tempExtraFieldList[cus.configItemFieldId] = cus.value;
          });
          this.tableData1.push(item);
        });
        this.page1Obj.total = res.totalElements;
      });
    },
    // 获取知识库配置项
    gettKnowledgeBaseItem() {
      this.tableData2 = [];
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: {
          pageNumber: this.page2Obj.currentPage - 1,
          pageSize: this.page2Obj.pageSize,
          processAreaIdList: this.table2CheckprocessAreaList,
          controlMethodIdList: this.table2CheckcontolList,
        },
      };
      getKnowledgeBaseViewConfigItem(params).then((res) => {
        res.content.forEach((item) => {
          item.extraFieldList ? "" : (item.extraFieldList = []);
          item.tempExtraFieldList = {};
          item.extraFieldList.forEach((cus) => {
            item.tempExtraFieldList[cus.configItemFieldId] = cus.value;
          });
          this.tableData2.push(item);
        });
        this.page2Obj.total = res.totalElements;
      });
    },
    // 文件配置表格分页事件
    table1pageChange() {
      this.getFileConfigItem();
    },
    table2pageChange() {
      this.gettKnowledgeBaseItem();
    },
    editFn() {
      // this.isEdit = true;
      // this.activeNames = ["1"];
      this.copyDialog = true;
      this.copyChartForm = JSON.parse(JSON.stringify(this.currentConfigObj));
    },
    copyDialogFn() {
      this.copyChartForm = {
        name: "",
        description: "",
      };
      this.copyDialog = true;
    },
    // 复制
    copyFn() {
      this.$refs.addDatachartForm.validate((valid) => {
        if (valid) {
          if (this.copyChartForm.configItemId) {
            const params = {
              projectId: this.get_pid(),
              configItemId: this.currentConfigurationId,
              data: {
                name: this.copyChartForm.name,
                description: this.copyChartForm.description,
              },
            };
            editConfiguration(params)
              .then((res) => {
                this.copyDialog = false;
                this.$emit("getAllConfig", () => {
                  this.isEdit = false;
                  this.selectConfig(this.currentConfigurationId);
                  this.copyChartForm = {
                    name: "",
                    description: "",
                  };
                });
              })
              .catch((err) => {});
          } else {
            const params = {
              projectId: this.get_pid(),
              configItemId: this.currentConfigurationId,
              data: {
                name: this.copyChartForm.name,
                description: this.copyChartForm.description,
              },
            };
            copyConfig(params).then((res) => {
              this.$emit("getAllConfig", () => {
                this.isEdit = false;
                this.copyDialog = false;
                this.selectConfig(res);
                this.$message({
                  type: "success",
                  message: this.$t("AI.tip14"),
                });
              });
            });
          }
        } else {
          return false;
        }
      });
    },
    exitEdit() {
      this.isEdit = false;
      this.activeNames = [];
    },
    editConfig() {
      this.isAutoSave = true;
      const params = {
        projectId: this.get_pid(),
        configItemId: this.currentConfigurationId,
        data: {
          name: this.currentConfigObj.name,
          description: this.currentConfigObj.description,
        },
      };
      editConfiguration(params)
        .then((res) => {
          this.isAutoSave = false;
        })
        .catch((err) => {
          this.isAutoSave = false;
        });
    },
    deleteFn() {
      this.$confirm(
        this.$t("configurationManagement.deleteTip"),
        this.$t("baseline.topBar.deleteBaselineForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      ).then(() => {
        const params = {
          projectId: this.get_pid(),
          configItemId: this.currentConfigurationId,
        };
        deleteConfiguration(params).then((res) => {
          this.$emit("getAllConfig", () => {
            if (this.configurationOptions.length) {
              this.selectConfig(this.configurationOptions[0].configItemId);
            }
            this.isEdit = false;
          });
        });
      });
    },
    selectConfig(value) {
      this.currentConfigurationId = value;
      this.configurationOptions.forEach((item) => {
        if (item.configItemId === value) {
          this.currentConfigObj = item;
        }
      });
      this.getFileConfigItem();
      this.gettKnowledgeBaseItem();
    },
    getFileOptions(str) {
      get_file_List(true, 0, 50, this.get_pid(), "fileKey", [], str).then(
        async (res) => {
          this.fileOptions = res.content;
        }
      );
    },
    getKnowledgeBaseOptions(str) {
      const params = {
        projectId: this.get_pid(),
        data: {
          fuzzyKey: str,
        },
      };
      documentsFuzzy(params).then((res) => {
        this.knowledgeBaseOptions = res;
      });
    },
    /**
     * 添加配置对话框函数
     * 用于显示添加新配置的对话框
     */
    addConfigurationDialogShow() {
      this.$emit("changeDialogShow", true);
    },
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("1") == -1 && !this.isEdit) {
          this.activeNames.push("1");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("1") !== -1 && !this.isEdit) {
        this.activeNames.splice(this.activeNames.indexOf("1"), 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 顶部下拉框和按钮组
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__version-select {
    ::v-deep.el-input__inner {
      width: 260px;
      background-color: transparent;
    }
  }
  &__version-edit {
    .version-name {
      font-size: 18px;
      color: rgba(128, 128, 128, 1);
    }
    ::v-deep.el-input {
      width: 260px;
      .el-input__inner {
        font-size: 16px;
        background-color: transparent;
      }
    }
  }
  &__btns-edit {
    display: flex;
    align-items: center;
  }
}
.version-add {
  font-size: 18px;
  width: 100%;
}

::v-deep.el-select-dropdown__item.version-add-option::after {
  content: "";
}
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}
.version-info {
  padding: 17px 23px 0px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: rgba(128, 128, 128, 1);
  text-align: left;
}
.icon-save-list {
  // 图标单独样式 不继承按钮
  color: #174fa9;
  background-color: #eee;
  font-size: 25px;
  cursor: pointer;

  &:hover {
    color: #174fa9;
  }
}
.scroll_body {
  flex: 1;
  overflow: hidden;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
}
.table_collapse {
  display: flex;
  height: 100%;
  flex-direction: column;
  ::v-deep .collapse-main {
    margin-left: 0;
  }
}
::v-deep .el-pagination {
  display: inline-block;
  .btn-prev {
    background: transparent !important;
  }
  .btn-next {
    background: transparent !important;
  }
  li {
    background: transparent !important;
  }
}
.add_node_wrap {
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  ::v-deep .el-select-dropdown {
    max-width: 400px;
  }
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 1px !important;
  }
}
.baseline-nodes {
  margin-top: 10px;
}
.word_button {
  height: 30px;
  border: 0;
  background: transparent;
  margin-top: -3px;
  white-space: nowrap;
  cursor: pointer;
  color: rgba(1, 76, 170, 1);
  font-weight: 100;
}

.word_button:hover {
  color: #0074b7;
}
.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;

  .checkbox {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    width: 100%;
  }
}

.pingshen-status-tag {
  padding: 4px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  border-width: 1px;
}
.cus_options_class {
  margin: 10px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.check_box_wrap {
  position: relative;
  width: fit-content;
  display: inline-block;
  border: 1px solid rgb(220, 223, 230);
  border-radius: 3px;
  padding: 3px 15px;
  margin: 4px;
}

.check_box_wrap_delete {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  background-color: #a6a6a6;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  right: -2px;
  top: -2px;
  z-index: 9;
  font-size: 12px;
}
.checked-label {
  border: 1px solid #336690;
  background-color: #336690;
  color: #fff;
  padding: 0px 4px;
  border-radius: 4px;
  display: inline-block;
  & > i {
    color: #fff !important;
  }
}
</style>
<style lang="scss">
.baseline-nodes .el-table .el-table__body-wrapper {
  height: calc(100% - 50px) !important;
}
</style>
