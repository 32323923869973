import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 查询所有配置项
export function getAllconfiguration(projectId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/configItem/all`,
        method: 'post'
    })
}
// 删除配置项
export function deleteConfiguration(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}`,
        method: 'delete'
    })
}
// 新增配置项
export function addConfiguration(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem`,
        method: 'post',
        data: params.data
    })
}
// 新增配置项
export function editConfiguration(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}`,
        method: 'put',
        data: params.data
    })
}
// 新增文件配置项
export function addFileConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/fileConfigItem`,
        method: 'post',
        data: params.data
    })
}
// 新增文件配置项
export function getFileConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/fileConfigItem/filter`,
        method: 'post',
        data: params.data
    })
}
// 删除文件配置项
export function deleteFileConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/fileConfigItem/${params.fileConfigItemId}`,
        method: 'delete',
    })
}
// 删除文件配置项
export function editFileConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/fileConfigItem/${params.fileConfigItemId}`,
        method: 'put',
        data: params.data
    })
}
// 查询配置项自定义字段
export function getCustomField(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItemField`,
        method: 'get',
    })
}
// 新增配置项自定义字段
export function addCustomField(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItemField`,
        method: 'post',
        data: params.data
    })
}
// 删除配置项自定义字段
export function deleteCustomField(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItemField/${params.configItemFieldId}`,
        method: 'delete',
    })
}
// 修改配置项自定义字段
export function editCustomField(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItemField/${params.configItemFieldId}`,
        method: 'put',
        data: params.data
    })
}
// 查询知识库配置项
export function getKnowledgeBaseViewConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/knowledgeBaseViewConfigItem/filter`,
        method: 'post',
        data: params.data
    })
}
// 新增知识库配置项
export function addKnowledgeBaseViewConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/knowledgeBaseViewConfigItem`,
        method: 'post',
        data: params.data
    })
}
// 修改知识库配置项
export function editKnowledgeBaseViewConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/knowledgeBaseViewConfigItem/${params.knowledgeBaseViewConfigId}`,
        method: 'put',
        data: params.data
    })
}
// 删除知识库配置项
export function deleteKnowledgeBaseViewConfigItem(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/knowledgeBaseViewConfigItem/${params.knowledgeBaseViewConfigId}`,
        method: 'delete',
    })
}
// 模糊搜索知识库
export function documentsFuzzy(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/fuzzy`,
        method: 'get',
        params: params.data
    })
}
// 获取过程域
export function getProcessArea(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/processArea`,
        method: 'get',
    })
}
// 编辑过程域
export function editProcessArea(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/processArea/${params.processAreaId}`,
        method: 'put',
        data: params.data
    })
}
// 新增过程域
export function addProcessArea(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/processArea`,
        method: 'post',
        data: params.data
    })
}
// 删除过程域
export function deletetProcessArea(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/processArea/${params.processAreaId}`,
        method: 'delete',
    })
}
// 获取管控方式
export function getControlMethod(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/controlMethod`,
        method: 'get',
    })
}
// 编辑管控方式
export function editControlMethod(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/controlMethod/${params.controlMethodId}`,
        method: 'put',
        data: params.data
    })
}
// 新增管控方式
export function addControlMethod(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/controlMethod`,
        method: 'post',
        data: params.data
    })
}
// 删除管控方式
export function deletetControlMethod(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/controlMethod/${params.controlMethodId}`,
        method: 'delete',
    })
}
// 复制配置管理
export function copyConfig(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/copy`,
        method: 'post',
        data: params.data
    })
}
// 获取存档
export function getArchive(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/archive`,
        method: 'get',
        params: params.data
    })
}
//存档
export function postArchive(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/configItem/${params.configItemId}/archive`,
        method: 'post',
        data: params.data
    })
}
